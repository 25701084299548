.container {
  /* width: 100px; */
  min-width: 90px;
  height: 100px;
  position: relative;
  border-radius: 0.2rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
  backface-visibility: hidden;
  transition: background-color 0.3s;
}

.subContainer {
  display: flex;
  height: 100%;
  width: 100%;
}

.boxLabel {
  position: absolute;
  bottom: 0;
  font-size: 1.05em;
  background-color: #000;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
  padding: 0.2em;
  text-transform: uppercase;
  backface-visibility: hidden;
  transition: background-color 0.3s;
}

.selectedLabelColor {
  background-color: #20a0ff;
  transition: background-color 0.3s;
}

.half {
  width: 50%;
  height: 100%;
  position: relative;
}

.deleteWrapper {
  position: absolute;
  right: 5px;
  top: 5px;
}

.copyWrapper {
  position: absolute;
  left: 5px;
  top: 5px;
}
