.container {
  position: relative;
  width: 75%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 2rem;
  outline: none;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
  margin-top: 4.5%;
  height: 65%;
}

.closeIcon {
  position: absolute;
  right: 5px;
  top: 5px;
}

@media (max-width: 960px) {
  .container {
    width: calc(100% - 4rem);
    margin-top: 0;
    height: calc(100% - 4rem);
  }
}
