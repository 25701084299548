.container {
  width: 100%;
  max-height: 460px;
  display: flex;
  flex-direction: column;
}

.imageContainer {
  background-color: #fff;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  flex: 1;
}

.imageContainer img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 379px;
}

.bottomContainer {
  height: 45px;
  margin-top: 8px;
  display: flex;
}

.popupImageBox {
  background-color: #fff;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
  padding: 15px;
  max-width: 600px;
}

.image {
  /* min-width: 130px;
  max-width: 130px;*/
  min-height: 130px;
  max-height: 130px;
  display: flex;
  cursor: pointer;
}

.image img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 130px;
}

@media (max-width: 960px) {
  .container {
    min-height: 380px;
  }
}
