.mainContainer {
  position: relative;
}

.container {
  background: #3d4049;
  height: 35px;
  width: 65px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -12px;
  position: absolute;
  left: -32px;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
}

.container:hover {
  background: #2e3036;
}

@keyframes rollIn {
  0% {
    opacity: 1;
    height: 100px;
    width: 90px;
    transform: scale(1.3) translateX(-280%) translateY(230%) rotate(-120deg);
  }
  100% {
    opacity: 0;
    height: 35px;
    width: 65px;
    transform: translateX(0px) rotate(0deg);
  }
}

.animationBox {
  position: absolute;
  height: 35px;
  width: 65px;
  top: -12px;
  position: absolute;
  left: -32px;
  z-index: 10;
}

.addAnimation {
  animation: rollIn 1200ms infinite alternate;
}

.deleteAnimation {
  animation: none;
}
