.gridWrapper {
  background-color: #f4f9ff;
  border-radius: 5px;
  width: 80%;
  padding: 1rem;
  margin: 0 auto;
  height: calc(100% - 2rem);
  overflow: auto;
}

.saveIcon {
  position: absolute;
  right: 12px;
  top: 60px;
}
