.container {
  flex-grow: 1;
  margin-bottom: 40px;
  position: relative;
}

.palette {
  position: absolute;
  right: 20px;
  top: 80px;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
}

@media (max-width: 1280px) {
  .palette {
    top: 75px;
  }
}

@media (max-width: 960px) {
  .palette {
    top: 65px;
  }
}

@media (max-width: 600px) {
  .palette {
    right: 5px;
    top: 58px;
  }
}
