.container {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  position: relative;
}

.subContainer {
  width: 70%;
  display: flex;
  justify-content: center;
}

.imageBox {
  display: flex;
  margin-right: 5%;
  min-width: 340px;
  flex-grow: 1;
  max-width: 700px;
}

.colorBox {
  max-width: 380px;
  min-width: 340px;
}

.helpButton {
  position: absolute;
  top: 70px;
  left: 15px;
}

@media (max-width: 1280px) {
  .subContainer {
    width: 80%;
  }
}

@media (max-width: 960px) {
  .subContainer {
    flex-direction: column;
  }

  .sliderBox {
    margin-right: 0;
  }

  .colorBox {
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
  }

  .helpButton {
    top: 55px;
  }

  .imageBox {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .subContainer {
    width: 100%;
  }
  .helpButton {
    top: 47px;
  }
}
