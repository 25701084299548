.container {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-content: space-between;
}

.header {
  position: relative;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin-bottom: 20px;
}

.colorsContainer {
  padding: 0 20px 20px 20px;
}
